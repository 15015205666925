module.exports = {
	'menu.register.now': 'Register now',
	'menu.register': 'register online',
	'menu.language': 'Language',
	'menu.language.english': 'English',
	'menu.language.polish': 'Polish',
	'menu.about': 'About us',
	'menu.services': 'Services',
	'menu.services.aestheticDentistry': 'Aestetic dentistry',
	'menu.services.implantology': 'Implantology',
	'menu.services.comprehensiveDentalCare': 'Comprehensive dental care',
	'menu.services.aestheticMedicine': 'Aesthetic medicine',
	'menu.team': 'Team',
	'menu.team.radoslaw': 'dr n. med. Radosław Łysek',
	'menu.team.karolina': 'Lek. dent. Karolina Krempa',
	'menu.team.paulina': 'Lek. dent. Paulina Gęsich',
	'menu.team.dorota': 'Dorota Maliszewska',
	'menu.team.monika': 'Monika Bochenek',
	'menu.team.elwira': 'Elwira Zawiślak',
	'menu.pricing': 'Pricing',
	'menu.blog': 'Blog',
	'menu.contact': 'Contact',

	'headerTop.aestheticDentistry': 'Aestetic dentistry',
	'headerTop.implantology': 'Implantology',
	'headerTop.comprehensiveDentalCare': 'Comprehensive dental care',
	'headerTop.aestheticMedicine': 'Aesthetic medicine',

    'aboutUs.about': 'About us',
	'aboutUs.about.test':'<span2>Dental Arts Studio</span2> was created for demanding patients. People for who care about the quality of dental care. No hurry. With attention and care. Getting to know the customs and preferences of the patient. Adjusting the therapeutic procedure and the scope of diagnostics to the verified scope of needs. You will see how much you gain when you decide on a dedicated dentistry. Make an appointment for a consultation today. I am pleased to invite you to a meeting.',
	'aboutUs.about.description':`<span>Dental Arts Studio</span> was created for demanding patients. People for who care about the quality of dental care. No hurry. With attention and care. Getting to know the customs and preferences of the patient. Adjusting the therapeutic procedure and the scope of diagnostics to the verified scope of needs. You will see how much you gain when you decide on a dedicated dentistry. Make an appointment for a consultation today. I am pleased to invite you to a meeting.`,
    'aboutUs.dedicatedDentistry': 'Dedicated dentistry',
	'aboutUs.dedicatedDentistry.description': '<span>The art of dentistry.</span> Dental Arts Studio sets a new standard. Imagine a smooth registration, delicious good morning coffee and a staff that knows your needs. You can count on careful service in every area of dental care. We are opening a new office in Krakow. The style commonly known abroad and described as boutique dentistry is a philosophy of dedicated dental services.',
	'aboutUs.dedicatedDentistry.manifest': `<pbrown>Our manifesto: prestigious, discreet and uniquely patient-oriented dentistry. Exclusive.</pbrown>`,
    'aboutUs.prestigeAsStandard': 'Prestige as standard',
    'aboutUs.prestigeAsStandard.description1': '<span>A place for demanding customers.</span> We put your needs above the multiplication of treatments and quick visits. We care about effective use of time. Our waiting room can be a place for you, where, having at your disposal many interesting books and magazines, with delicious coffee, you can relax, listening to good music.',
    'aboutUs.prestigeAsStandard.description2': '<span>More than a visit.</span> We carefully analyze every case that requires treatment. We treat prophylaxis comprehensively. We use advanced preventive dentistry.',
    'aboutUs.prestigeAsStandard.description3': '<span>Quality dentistry.</span> Precision, diligence and exceptional class - these are our priorities. We <span>eliminate</span> inconvenience, randomness and commonness. Focused on your needs, effective in action, <span>extremely</span> accurate....',
    'aboutUs.prestigeAsStandard.description4': 'Our team in the service of your satisfaction. ',
    'aboutUs.prestigeAsStandard.description5': '<span>Professionalism raised to the power of comfort.</span> You will appreciate the discretion and culture that accompanies every meeting. We want the sense of uniqueness to raise the standard of dentistry.',

    'services.aestheticDentistry.naturalBeauty': 'natural beauty',
    'services.aestheticDentistry.naturalBeauty.description': '<span>Aesthetic dentistry</span> is designing a beautiful smile. It is a procedure of restoring the natural attractiveness of teeth that have been exposed to harmful external factors and often dietary deficits with the passage of time. We will match the teeth with the appropriate shape and shade. The possibility of using implants, veneers or all-ceramic crowns gives unlimited field of action of aesthetic dentistry.',
    'services.aestheticDentistry.smileMetamorphosis': 'smile metamorphosis',
	'services.aestheticDentistry.smileMetamorphosis.description': '<span>Dental Arts Studio</span> Kraków has a complete facilities enabling comprehensive treatments of aesthetic dentistry. The most modern and technologically advanced systems support the experience and knowledge of our staff. The harmony of beautiful teeth is a combination of knowledge, experience and dental materials of the highest quality.',
	'services.aestheticDentistry.smileMetamorphosis.description.li4': '<span>Whitening</span> - professional and safe methods of restoring the proper shade of teeth ',
	'services.aestheticDentistry.smileMetamorphosis.description.li5': '<span>Cleaning and sandblasting</span> - reducing deposits and discolouration in a safe way ',
	'services.aestheticDentistry.smileMetamorphosis.description.li1': '<span>Implants</span> - permanent and aesthetic treatment, safety for the jaw, long life of the method ',
	'services.aestheticDentistry.smileMetamorphosis.description.li2': '<span>Veneers</span> - for minimal and medium corrections to the shape and colour of teeth, ',
	'services.aestheticDentistry.smileMetamorphosis.description.li3': '<span>All-ceramic crowns</span> - possibility of repairing very damaged teeth',
		
	'services.implantology.safeCorrection': 'safe correction',
    'services.implantology.safeCorrection.description': 'Implantology comes to your aid, providing a safe alternative to dentures and bridges. The technique of implant insertion is a professional and proven in its effectiveness treatment. Guaranteed freedom of eating, speaking with the right diction, no risk of exposing neighboring teeth.',
    'services.implantology.benefitsForYou': 'benefits for you',
    'services.implantology.benefitsForYou.description': 'Dental Arts Studio Kraków specialists introduce implants with the use of the latest dental equipment and materials exclusively of premium class. A beautiful smile - guaranteed. The feeling of own, natural teeth, without any discomfort - ensured. Prevention of bone atrophy. Treatment with a permanent character and long satisfactory exploitation.',
    'services.implantology.recommendations': 'recommendations',
	'services.implantology.recommendations.head': 'For the success of the implantation/bone reconstruction procedure, it is important that you should:',
	'services.implantology.recommendations.td1': 'Remove the sterile swab from the mouth only 30 minutes after the procedure.',
	'services.implantology.recommendations.td2': 'Take medication as directed.',
	'services.implantology.recommendations.td3': 'Take care of your oral hygiene using a very soft toothbrush and antiseptic fluid',
	'services.implantology.recommendations.td4': 'Contact us if something bothers you',
	'services.implantology.recommendations.td5': 'Use cold compresses on the area to be operated on',
	'services.implantology.recommendations.td6': 'Drink only cool still drinks for 48 hours after the treatment',
	'services.implantology.recommendations.td7': 'Eat only cool, soft, semi-liquid, lightly seasoned dishes for 48 hours after the treatment.',
	'services.implantology.recommendations.td8': 'Come for a follow-up visit',
	'services.implantology.recommendations.head2': 'For proper healing after implantation/restoration of the bone, it is not allowed:',
	'services.implantology.recommendations.td9': 'Drink hot, carbonated beverages for 48 hours',
	'services.implantology.recommendations.td10': 'Eat for 2 hours after surgery',
	'services.implantology.recommendations.td11': 'Smoke tobacco, electronic cigarettes for 48 hours after surgery',
	'services.implantology.recommendations.td12': 'Eat hot, heavily seasoned foods and foods that require strong biting and chewing for 48 hours after the treatment',
	'services.implantology.recommendations.td13': 'Drink alcohol for 7 days after surgery',
	'services.implantology.recommendations.td14': 'Reduce exercise to a minimum for 7 days',
	'services.implantology.recommendations.td15': 'Do not use sauna, solarium for 7 days',
	'services.implantology.recommendations.td16': 'Do not "check" the area around the procedure with your tongue or by tilting your lip, cheek or cheek',
	'services.implantology.recommendations.td17': 'In case of maxillary sinus floor lift for 7 days avoid activities that are associated with a change in pressure, e.g. flight, diving, mountain trips. Do not clean your nose by blowing, do not play on wind instruments, e.g. flute, clarinet, trumpet, trombone.',

	'services.comprehensiveDentalCare.takingCareOfYourHealth': 'taking care of your health',
	'services.comprehensiveDentalCare.takingCareOfYourHealth.description':
		'Premium care. We ensure the highest level of preventive and therapeutic care, careful prevention and painless treatment. Regular visits will prevent you from having to treat your teeth, which are protected by dental protection early enough to maintain their natural condition for longer.',
	'services.comprehensiveDentalCare.treatmentAndPrevention': 'treatment and prevention',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description':
		'Doctors and specialists from Dental Arts Studio Kraków are passionate about fulfilling the obligation of caring for your health. Thanks to professional conservative dentistry, we can prevent carious erosion of enamel. We treat cavities painlessly. We provide the highest comfort of treatments. Endodontics. We achieve the highest level of precision in the treatment of dental pulp diseases and periapical tissues. Electronic tools support us in measuring and performing treatments. The most modern equipment allows us to maximize the effects of treatment.',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li1': 'dentistry consultation',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li2': 'professional hygienization',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li3': 'conservative treatment',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li4': 'microscopic endodontics',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li5': 'root canal treatment',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li6': 'implantology',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li7': 'x-ray teeth',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li8': 'x-ray point',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li9': 'dental tomography',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li10': 'surgery',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li11': 'pantomogram',

	'team.radoslaw.specialist': 'dentist',
	'team.radoslaw.description': 'Professional in the field of aesthetic dentistry and implant-prosthetics. He deals with complex treatment planning with the use of advanced technologies and visual techniques. He coordinates the work of specialists, taking care of the schedule and cost estimate. Creator and owner of Dental Arts Studio, which is an expression of many years of reflection on the organization of modern dental care. He graduated from the Faculty of Medicine of the Jagiellonian University Medical College, where he also defended his doctoral thesis. Specialist in Public Health. Participant of numerous courses, whose subject matter includes minimally invasive techniques of work, all-ceramic reconstruction, digital smile design, comprehensive treatment planning. He is fluent in English. Privately, a lover of travel and stock market investments.',
	'team.karolina.specialist': 'dentist',
	'team.karolina.description': `A graduate of the Faculty of Medicine and Dentistry at the Collegium Medicum of the Jagiellonian University in Krakow. In his daily work, he focuses on conservative dentistry and prosthetics.

	Working as a dentist is her passion.
	
	Active participant in numerous scientific conferences and courses in the field of modern dentistry. He is a person who is constantly developing and consistently expanding his knowledge and practical skills. In his work, he is guided by high accuracy and treatment precision.
	
	He approaches patients individually, trying to present problems and treatment options in the best possible way.
	
	She pays special attention to the comfort of patients and minimizing stress during visits. Privately, I collect travel memories.`,
	'team.paulina.specialist': 'dentist',
	'team.paulina.description': 'She graduated from the Faculty of Dentistry and Medicine at the Jagiellonian University Medical College in Cracow. She performs procedures in the field of primary and secondary root canal treatment, removal of broken tools and crown and root inserts, closing perforations. She performs all endodontic procedures at the magnification of the surgical microscope. She is a participant and a graduate of numerous trainings, conferences and conventions on microscopic endodontics, aesthetic restoration of teeth, aesthetic medicine and occlusion, as well as treatment of tooth wear. She is a graduate of postgraduate studies in Aesthetic Medicine for doctors at the Krakow School of Health Promotion. Privately, she is passionate about photography and enjoys travelling and good cuisine.',
	'team.dorota.specialist': 'Dental hygienist',
	'team.dorota.description': 'Dorota Maliszewska is a graduate of Medical Professional College. Associated with dentistry for 10 years. She is constantly expanding her professional competences by participating in trainings and courses in the field of dentistry and hygiene. The motivation for further professional development is to observe excellent results of treatment and satisfied patients. Nice, energetic and very open person. She likes to spend her free time actively.',
	'team.monika.specialist': 'Higienistka Stomatologiczna',
	'team.monika.description':
		`A graduate of the Krakow Post-Secondary Medical School, major: Dental Hygienist.
		Associated with dentistry since 2010.
		An optimist by nature, she enjoys working with people. Cares for a peaceful atmosphere during treatments.
		Always nice and helpful. With his smile, he overcomes the potential stress associated with visiting the dentist in patients. Her good energy is given to patients. There are no insoluble matters for her.
		He professionally performs hygiene procedures and oral hygiene instructions.
		He specializes in the Icon method.
		Her particular love is teeth whitening using the immediate and overlay method.
		
		Very conscientious and ambitious. She constantly improves her qualifications by participating for many years in courses in the field of scaling, polishing, whitening and the Icon method.
		
		Privately, I have a few-year-old boy, in my free time I am constantly looking for novelties in oral hygiene, loves to bake and read, and is also interested in kinesiotaping.`,
	'team.elwira.specialist': 'Dental hygienist',
	'team.elwira.description': "She graduated from the Medical College in Cracow. She has been working in dentistry for 8 years. Graduate of the University of Agriculture. Continuously expand her knowledge and skills. Currently, she is in the process of obtaining hygienist's certificates. In our office you can meet her as a nice, always smiling and willing to help person. Very conscientious, dedicated to her work and patients.",


	'pricing.stomatology': 'STOMATOLOGY',
	'pricing.aestheticMedicine': 'AESTHETIC MEDICINE',
	'pricing.price': 'PRICE*',
	'pricing.stomatology.td1': 'Overview',
	'pricing.stomatology.td2': 'Consultation',
	'pricing.stomatology.td3': 'Cost estimate of treatment',
	'pricing.stomatology.td4': 'Diagnostic package/treatment plan - 2 visits (examination, consultation, pantomographic image, diagnostic models, preparation of treatment plan)',
	'pricing.stomatology.td5': 'Designing a smile (examination, consultation, execution and analysis of a prototype of a "new smile")',

	'pricing.conservativeDentistry': 'CONSERVATIVE DENTISTRY',
	'pricing.conservativeDentistry.td1': 'Composite filling - small',
	'pricing.conservativeDentistry.td2': 'Reconstruction of a tooth for prosthetic work',
	'pricing.conservativeDentistry.td3': 'Temporary dressing',
	'pricing.conservativeDentistry.td4': 'Aesthetic correction of tooth shape with composite material',
	'pricing.conservativeDentistry.td5': 'Reconstruction of the tooth with a fibreglass insert',
	'pricing.conservativeDentistry.td6': 'Reconstruction of the tooth with a fibreglass insert - one root tooth',
	'pricing.conservativeDentistry.td7': 'Reconstruction of the tooth with a fibreglass insert - multi-root tooth',

	'pricing.endodonticTreatment': 'ENDODONTIC TREATMENT',
	'pricing.endodonticTreatment.td1': 'Endodontic intervention (emergency assistance in case of pain)',
	'pricing.endodonticTreatment.td2': 'Endodontic treatment - foretooth',
	'pricing.endodonticTreatment.td3': 'Endodontic treatment - premolar',
	'pricing.endodonticTreatment.td4': 'Endodontic treatment - a molar',
	'pricing.endodonticTreatment.td5': 'Repeated endodontic treatment - foretooth',
	'pricing.endodonticTreatment.td6': 'Repeated endodontic treatment - premolar',
	'pricing.endodonticTreatment.td7': 'Repeated endodontic treatment - molar',
	'pricing.endodonticTreatment.td8': 'Reconstruction of a damaged tooth for endodontic treatment',
	'pricing.endodonticTreatment.td9': 'Removal of the old root and crown insert',

	'pricing.aesthetics': 'AESTHETICS/PROSTHETICS',
	'pricing.aesthetics.td1': 'Composite veneer',
	'pricing.aesthetics.td2': 'Porcelain veneer',
	'pricing.aesthetics.td3': 'Composite crown',
	'pricing.aesthetics.td4': 'Porcelain crown on metal',
	'pricing.aesthetics.td5': 'All-ceramic crown',
	'pricing.aesthetics.td6': 'Inlay / Onlay / Overlay porcelain',
	'pricing.aesthetics.td7': 'Inlay / Onlay / Overlay composite',
	'pricing.aesthetics.td8': 'Temporary crown / veneer',
	'pricing.aesthetics.td9': 'Removal of the old crown',
	'pricing.aesthetics.td10': 'Partial acrylic denture (for 7 teeth)',
	'pricing.aesthetics.td11': 'Partial acrylic denture (for 8 teeth)',
	'pricing.aesthetics.td12': 'Skeletal denture',
	'pricing.aesthetics.td13': 'Combined work',
	'pricing.aesthetics.td13.description': 'individual valuation based on treatment plan',

	'pricing.implantology': 'IMPLANTOLOGY / SURGERY',
	'pricing.implantology.td1': 'Implant',
	'pricing.implantology.td2': 'Maxillary sinus floor lifting',
	'pricing.implantology.td3': 'Augmentation',
	'pricing.implantology.td4': 'Simple tooth extraction',
	'pricing.implantology.td5': 'Surgical tooth extraction',
	'pricing.implantology.td6': 'Crown on dental implant - ceramic on individual abutment',
	'pricing.implantology.td7': 'Crown on implant - steel on standard abutment',

	'pricing.orthodonticTreatment': 'ORTHODONTIC TREATMENT',
	'pricing.orthodonticTreatment.td1': 'Orthodontic consultation',
	'pricing.orthodonticTreatment.td2': 'Fixed metal braces',
	'pricing.orthodonticTreatment.td3': 'Fixed aesthetic braces',
	'pricing.orthodonticTreatment.td4': 'Fixed, mixed braces',
	'pricing.orthodonticTreatment.td5': 'Removable functional braces',

	'pricing.bleaching': 'TEETH BLEACHING / HYGIENE / PREVENTION',
	'pricing.bleaching.td1': 'Household bleaching - overlay',
	'pricing.bleaching.td2': "Bleaching in the dentist's office - Prevdent system",
	'pricing.bleaching.td3': 'Whitening of discoloured teeth after root canal treatment - first visit',
	'pricing.bleaching.td4': 'Whitening of discoloured teeth after root canal treatment - another visit',
	'pricing.bleaching.td5': 'Contact fluoridation',
	'pricing.bleaching.td6': 'Oral hygiene + professional teeth bleaching - system BlancOne',
	'pricing.bleaching.td7': 'Oral hygiene (descaling, sandblasting, fluoridation)',

	'pricing.xRay': 'X-RAY',
	'pricing.xRay.td1': 'point x-ray',
	'pricing.xRay.td2': 'pantomographic x-ray',
	'pricing.xRay.td3': 'cephalometric x-ray',
	'pricing.xRay.td4': 'cbct - endo ct',

	'pricing.record': 'MAKING MEDICAL RECORDS AVAILABLE',
	'pricing.record.td1': 'Copy for one page',
	'pricing.record.td2': 'Copy/print for one page',
	'pricing.record.td3': 'Documentation on computer storage medium (all documentation)',

	'pricing.azzalure': 'PRICE LIST OF TREATMENTS WITH <img/> PREPARATION',
	'pricing.azzalure.area': 'Application area',
	'pricing.azzalure.td1': "Lion's wrinkle (longitudinal wrinkle on the forehead)",
	'pricing.azzalure.td2': 'Horizontal wrinkles in the forehead',
	'pricing.azzalure.td3': "horizontal wrinkles + lion's wrinkles",
	'pricing.azzalure.td4': "crow's feet",
	'pricing.azzalure.td5': "crow's feet + lion's wrinkle",
	'pricing.azzalure.td6': "crow's feet + horizontal wrinkles + lion's wrinkles + lion's wrinkles",
	'pricing.azzalure.td7': 'nose wrinkles (rabbit wrinkles)',
	'pricing.azzalure.td8': 'nose tip elevation',
	'pricing.azzalure.td9': 'gummy smile',
	'pricing.azzalure.td10': 'beard',
	'pricing.azzalure.td11': 'the treatment of bruxism',

	'pricing.hyperhidrosis': 'PRIMARY HYPERHIDROSIS',
	'pricing.hyperhidrosis.td1': 'underarms/foots/hands',

	'pricing.mesotherapy': 'NEEDLE MESOTHERAPY',
	'pricing.mesotherapy.th1': 'number of treatments',
	'pricing.mesotherapy.th2': '1 treatment',
	'pricing.mesotherapy.th3': '4 treatments',
	'pricing.mesotherapy.th4': 'Face or Necline',
	'pricing.mesotherapy.th5': 'Skalp',
	'pricing.mesotherapy.td1': 'Neauvia Hydro Deluxe',
	'pricing.mesotherapy.td2': 'Filorga NCTF 135HA',
	'pricing.mesotherapy.td3': 'Linerase (face, neck, neckline and hands at the same time)',
	'pricing.mesotherapy.td4': 'Strengthening therapy - dr. Cyj',
	'pricing.mesotherapy.td5': 'Dermaheal HL',

	'pricing.injection': 'INJECTION LIPOLYSIS',
	'pricing.injection.ampule': '1 ampoule',
	'pricing.injection.td1': 'Aqualyx',
	'pricing.injection.td1.description': 'At 3 or more ampoules - 250 /amp',
	'pricing.injection.td2': 'Belkyra - double chin treatment',
	'pricing.injection.td3': 'treatment of cellulite',
	'pricing.injection.td3.description1': '7 treatments',
	'pricing.injection.td3.description2': 'l2 treatments',

	'pricing.neauvia': 'FILLERS WITH NEAUVIA ®HIALURONIC ACID',
	'pricing.neauvia.area': 'Application area',
	'pricing.neauvia.td1': 'Filling in wrinkles and furrows',
	'pricing.neauvia.td2': 'Facial Volumetry (oval modeling)',
	'pricing.neauvia.td3': 'Nose or beard modelling',
	'pricing.neauvia.td4': 'Filling the valley of tears (Redensity II)',
	'pricing.neauvia.td5': 'Modelling and lip filling',

	'pricing.emerge': 'EMERGE FRACTIONAL LASER',
	'pricing.emerge.th1': 'Application area',
	'pricing.emerge.th2': '1 treatment',
	'pricing.emerge.th3': '4 treatments + 1 FREE',
	'pricing.emerge.td1': 'Face',
	'pricing.emerge.td2': 'Neck or neckline',
	'pricing.emerge.td3': 'Face + neck',
	'pricing.emerge.td4': 'Face + neck + neckline',
	'pricing.emerge.td5': "Eye area (crow's feet)",
	'pricing.emerge.td6': "Mouth area (smoker's wrinkles)",
	'pricing.emerge.td7': 'Forehead',
	'pricing.emerge.td8': 'Cheeks',
	'pricing.emerge.td9': 'Hands',

	'pricing.scars': 'TREATMENT OF SCARS OR STRETCH MARKS',
	'pricing.scars.th1': 'Surface of the area treated',
	'pricing.scars.th2': '1 treatment',
	'pricing.scars.th3': '4 treatments + 1 FREE',
	'pricing.scars.th4': '8 treatments + 2 freebies',
	'pricing.scars.td1': 'up to 5 cm2',
	'pricing.scars.td2': '5 to 10 cm2',
	'pricing.scars.td3': '10 to 15 cm2',
	'pricing.scars.td4': 'above 15 cm2',
	'pricing.scars.td4.description': 'individual price',

	'pricing.zl': '* prices in Polish zloty (PLN)',

	'contact.address': 'Address:',
	'contact.open': 'Opening hours:',
	'contact.monday': 'Monday',
	'contact.tuesday': 'Tuesday',
	'contact.wednesday': 'Wednesday',
	'contact.thursday': 'Thursday',
	'contact.friday': 'Friday',
	'contact.saturday': 'Saturday',
	'contact.sunday': 'Sunday',
	'contact.weekHour': '8:00 a.m. - 9:00 p.m.',
	'contact.close': 'Closed',
	'contact.onDuty': 'On duty',

	'contact.goTo': 'Go to',
	'contact.openMaps': 'Open Google Maps'
};
