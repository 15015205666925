import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { getCurrentLangKey } from 'ptz-i18n';
import { IntlProvider } from 'react-intl';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { library } from '@fortawesome/fontawesome-svg-core';
import { faTooth } from '@fortawesome/free-solid-svg-icons';
import { faBars } from '@fortawesome/free-solid-svg-icons';

// import Header from "./header"
import { Helmet } from 'react-helmet';
import Seo from './seo';
import Nav from './nav';
import Phone from './phone';
import Footer from './footer';
import '../styles/style.scss';
import './phone.scss';
// import messages from '../data/messages/pl';
import messagesPL from '../data/messages/pl';
import messagesSV from '../data/messages/sv';
import messagesEN from '../data/messages/en';

// import * as rdd from 'react-device-detect';
// rdd.isMobile = true;

if (typeof window !== 'undefined') {
	// eslint-disable-next-line global-require
	require('smooth-scroll')('a[href*="#"]', {
		offset: function (anchor, toggle) {
			return 170
		}
	});
}

library.add(faTooth);
library.add(faBars);
// const allParagraph = () => {
// 	setTimeout(() => {
// 		let p = document.querySelectorAll('p');
// 		p.forEach((v, k) => {
// 			// console.log(v);
// 			// console.log(v.innerHTML);
// 			let text = v.innerHTML;
// 			let regExp = / ([a-zA-Z]) {1}/g;
// 			v.innerHTML = text.replace(regExp, ' $1' + String.fromCharCode(160));
// 		});
// 	}, 5000);
// };
// allParagraph();

const Layout = ({ location, children, messages }) => {
	// console.log('layour 22');
	// console.log(props);
	// console.log(children);
	// console.log(messages);
	const data = useStaticQuery(graphql`
		query SiteTitleQuery {
			site {
				siteMetadata {
					title
					languages {
						langs
						defaultLangKey
					}
				}
			}
		}
	`);
	const url = location.pathname;
	const { langs, defaultLangKey } = data.site.siteMetadata.languages;
	const currentLang = getCurrentLangKey(langs, defaultLangKey, url);
	data.site.siteMetadata.languages.langKey = currentLang;

	messages = {
		en: messagesEN,
		pl: messagesPL,
		sv: messagesSV
	};
	// console.log('aaaa', url, langs, data);
	// console.log('aaaa', messages);
	// console.log('aaaa', messages.pl);
	// console.log('aaaa', messages[currentLang], currentLang);
	const obj = {
		// description: "a", lang: currentLang, meta: "v", keywords: ["s", "f"], title: "tak"
		lang: currentLang
	};
	return (
		<IntlProvider locale={currentLang} messages={messages[currentLang]}>
			<div>
				<Helmet>
					<link
						rel="stylesheet"
						href="https://use.fontawesome.com/releases/v5.7.2/css/solid.css"
						integrity="sha384-r/k8YTFqmlOaqRkZuSiE9trsrDXkh07mRaoGBMoDcmA58OHILZPsk29i2BsFng1B"
						crossorigin="anonymous"
					/>
					<link
						rel="stylesheet"
						href="https://use.fontawesome.com/releases/v5.7.2/css/fontawesome.css"
						integrity="sha384-4aon80D8rXCGx9ayDt85LbyUHeMWd3UiBaWliBlJ53yzm9hqN21A+o1pqoyK04h+"
						crossorigin="anonymous"
					/>
					<script src="https://www.medicall.biz/js/website_plugin.js" />
				</Helmet>
				<Seo {...obj} />
				<Nav location={location} lang={data.site.siteMetadata.languages} />
				<div>
					<main>{children}</main>
					<Footer lang={data.site.siteMetadata.languages} />
					<Phone />
				</div>
				{/* {allParagraph()} */}
			</div>
		</IntlProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default Layout;
