import React from 'react';
import Layout from '../components/layout';
// import messages from '../data/messages/pl';
// import { useStaticQuery, graphql } from 'gatsby';
// import { getCurrentLangKey } from 'ptz-i18n';




const pl = (props) => {
	// console.log('pl.js');
	// return <Layout {...props} messages={messages} />;
	return <Layout {...props} />;
};
export default pl;
