import React, { Component } from "react"
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps"

import logo from '../images/icon/logo-google-maps.png'
class Map extends Component {
  render() {
    const GoogleMapExample = withScriptjs(
      withGoogleMap(props => (
        <GoogleMap
          defaultCenter={{ lat: 50.0345205, lng: 19.93278380000004 }}
          defaultZoom={14}
          defaultOptions={{
              styles:
                [
                    {
                     featureType: 'poi',
                      stylers: [{visibility: 'off'}]
                    },
                    {
                        featureType: 'water',
                        stylers: [{color: '#a0cdd2'}]
                    },
                    {"stylers": [
                        {"saturation": -50}
                    ]
                }
                ]
              ,
            streetViewControl: false,
            scaleControl: false,
            zoomControl: false,
            panControl: false,
            mapTypeControl: false,
            rotateControl: false,
            fullscreenControl: false
          }}
        >
          <Marker
            icon={
              logo
            }
            title= {'Dental Arts Studio'}
            position={{ lat: 50.0345205, lng: 19.93278380000004 }}
          />
        </GoogleMap>
      ))
    )
    return (
        <GoogleMapExample
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyCdfCJWPslNJ6MHwij4ohWaX18jwDO67HI"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `400px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
    )
  }
}
export default Map
