import React, { Component } from 'react';

import Coffe from '../images/icon/coffe-cup.png';
import RTG from '../images/icon/radiation.png';
import Wifi from '../images/icon/wifi.png';
import Parking from '../images/icon/parking.png';
import Disabled from '../images/icon/disabled.png';
import Credit from '../images/icon/credit.png';
import Mediraty from '../images/icon/mediraty_logo_v.png';
import AirCon from '../images/icon/ice-crystal.png';
import register from '../images/icon/24hours.png';
import 'lazysizes';

import './icon-bar.scss';

class IconBar extends Component {

	render() {
		return (
			<div className="icon-bar-container">
				<div>
                    <img className="lazyload" data-src={Coffe} alt="darmowa kawa"/>
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>pyszna kawa</p>
                </div>
				<div>
                    <img data-src={RTG} className="lazyload" alt="RTG"/>
                    <p className={this.props.onlyPL ? "podpis small-center" : "podpis--hide"}>RTG</p>
                </div>
				<div>
                    <img className="lazyload" data-src={Wifi} alt="wifi"/>
                    <p className={this.props.onlyPL ? "podpis small-center" : "podpis--hide"}>wifi</p>
                </div>
				<div>
                    <img className="lazyload" data-src={register} alt="rejestracja 24h"/>
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>Rejestracja 24h@</p>
                </div>
				<div>
                    <img className="lazyload" data-src={Parking} alt="darmowy parking"/>
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>Darmowy parking</p>
                </div>
				<div>
                    <img className="lazyload" data-src={Disabled} alt="udogodnienia dla niepełnosprawnych"/>
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>Udogodnienia dla niepełnosprawnych</p>
                </div>
				<div>
                    <img className="lazyload" data-src={Credit} alt="płatność kartą" />
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>Płatność kartą</p>
                </div>
				<div>
                    <img className="lazyload" data-src={Mediraty} alt="medi raty"/>
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>Medi raty</p>
                </div>
				<div>
                    <img className="lazyload" data-src={AirCon} alt="klimatyzacja" />
                    <p className={this.props.onlyPL ? "podpis" : "podpis--hide"}>Klimatyzacja</p>
                </div>
			</div>
		);
	}
}
export default IconBar;
