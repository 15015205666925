import React, { Component } from 'react';
import logo from '../images/logo.svg';
import Map from '../components/maps';
import freeParking from '../images/parking2.png';
import IconBar from './IconBar';
import { FormattedMessage } from 'react-intl';

import oldPhone from '../images/icon/old-phone.png';
import newPhone from '../images/icon/new-phone.png';

import Insta from '../images/icon/instagram.png';
import Facebook from '../images/icon/facebook-logo.png';
import Mail from '../images/icon/envelope.png';

import goTo from '../images/goto.png';
import '../components/phone.scss';

class Footer extends Component {
	state = {
		isMobile: typeof window !== 'undefined' && window.innerWidth
	};
	static defaultProps = {
		center: {
			lat: 50.0345205,
			lng: 19.93278380000004
		},
		zoom: 14
	};

	componentDidMount() {
		// console.log('componentWillMount');
		window.addEventListener('resize', this.handleWindowSizeChange);
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({ isMobile: window.innerWidth });
	};

	handleClick = () => {
		// console.log('click');
		window.medicall_modal('f78b07a362fecf4542fa', 1);

		return false;
	};

	render() {
		let goToNavi;
		if (this.state.isMobile > 1200) {
			goToNavi = (
				<a href="https://goo.gl/maps/rMDPkcpwUG3zLzQD8" target="_blank" rel="noopener noreferrer">
					<img src={goTo} alt="goto" />
					<FormattedMessage id="contact.openMaps" tagName="p" />
					{/* <p>Otwórz Google Maps</p> */}
				</a>
			);
		} else {
			goToNavi = (
				<a href="google.navigation:q=50.0344659,19.9324407">
					<img src={goTo} alt="goto" />
					<FormattedMessage id="contact.goTo" tagName="p" />
					{/* <p>Prowadź do</p> */}
				</a>
			);
		}
		return (
			<div>
				<IconBar onlyPL={this.props.lang.langKey === 'pl'} />
				<div id="contact" ref={this.props.refProp}>
					<footer id="contact">
						<div className="footer__logo">
							<img src={logo} alt="Logo dental art studio" />
							<div className="social-media">
								<a
									href="https://www.facebook.com/DentalArtsStudioKrakow/"
									rel="noopener noreferrer"
									target="_blank"
								>
									<div className="hover face">
										<img src={Facebook} className="lazyload" alt="facebook" />
									</div>
								</a>
								<a
									href="https://www.instagram.com/sztukastomatologii.pl/"
									rel="noopener noreferrer"
									target="_blank"
								>
									<div className="hover insta">
										<img src={Insta} className="lazyload" alt="instagram" />
									</div>
								</a>
								<a href="mailto:kontakt@dentalartsstudio.pl">
									<div className="hover mail">
										<img src={Mail} className="lazyload" alt="mail" />
									</div>
								</a>
							</div>
						</div>

						<div className="footer__address">
							{/* <h3>Adres:</h3> */}
							<FormattedMessage id="contact.address" tagName="h3" />
							<p>
								Osiedle „Fi” <br />
								ul. Tadeusza Szafrana 5a/u7 <br />
								30-363 Kraków <br />
								{/* //TODO: */}
							</p>
							<div>
								<div>
									<img
										className="icon lazyload"
										data-src={newPhone}
										alt="numer telefonu komórkowego 508 502 802"
									/>508 502 802
								</div>
								<div>
									<img
										className="icon lazyload"
										data-src={oldPhone}
										alt="numer telefonu domowego 12 445 35 04"
									/>12 445 35 04
								</div>
								<div>
									<img
										className="icon lazyload"
										data-src={Mail}
										alt="Email kontakt@dentalartsstudio.pl"
									/>kontakt@dentalartsstudio.pl
								</div>
							</div>
						</div>
						<div className="footer__open">
							<table>
								<thead>
									<tr>
										<th>
											<FormattedMessage id="contact.open" tagName="h3" />
										</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<FormattedMessage id="contact.monday" tagName="td" />
										<FormattedMessage id="contact.weekHour" tagName="td" />
									</tr>
									<tr>
										<FormattedMessage id="contact.tuesday" tagName="td" />
										<FormattedMessage id="contact.weekHour" tagName="td" />
									</tr>
									<tr>
										<FormattedMessage id="contact.wednesday" tagName="td" />
										<FormattedMessage id="contact.weekHour" tagName="td" />
									</tr>
									<tr>
										<FormattedMessage id="contact.thursday" tagName="td" />
										<FormattedMessage id="contact.weekHour" tagName="td" />
									</tr>
									<tr>
										<FormattedMessage id="contact.friday" tagName="td" />
										<FormattedMessage id="contact.weekHour" tagName="td" />
									</tr>
									<tr>
										<FormattedMessage id="contact.saturday" tagName="td" />
										<FormattedMessage id="contact.onDuty" tagName="td" />
									</tr>
									<tr>
										<FormattedMessage id="contact.sunday" tagName="td" />
										<FormattedMessage id="contact.close" tagName="td" />
									</tr>
								</tbody>
							</table>
						</div>
						{/* <div className="footer__open">
							<h3>Godziny otwarcia:</h3>
							<FormattedMessage id="contact.open" tagName="h3" />
							<FormattedMessage id="contact.monday" tagName="p" />
							<FormattedMessage id="contact.tuesday" tagName="p" />
							<FormattedMessage id="contact.wednesday" tagName="p" />
							<FormattedMessage id="contact.thursday" tagName="p" />
							<FormattedMessage id="contact.friday" tagName="p" />
							<FormattedMessage id="contact.saturday" tagName="p" />
							<FormattedMessage id="contact.sunday" tagName="p" />
						</div> */}
						<div className="footer_map_and_registry">
							<button onClick={this.handleClick} className="registration to-upper-case">
								<FormattedMessage id="menu.register" />
							</button>
							<img className="lazyload" data-src={freeParking} alt="darmowy parking" />
						</div>
						<div style={{ gridColumn: '1/4' }}>
							<Map />
						</div>
						{/* <div>
              <p>
                <a href="geo:50.0344659,19.9324407">
                  Zwykły punkt z wyborem nawigacji</a>
              </p>
              <p>
                Start z wyborem nawigacji
              </p>
  
              <a href="https://goo.gl/maps/RMbxWNkM22nwgq1v6">Ustawia dokąd zawsze w google maps</a>
            </div> */}
						<div className="go-to">{goToNavi}</div>
						<div className="footer__design">Design Paweł Idzikowski</div>
					</footer>
				</div>
			</div>
		);
	}
}

export default Footer;
