module.exports = {
	'menu.register.now': 'Zarejestruj się',
	'menu.register': 'rejestracja online',
	'menu.language': 'Język',
	'menu.language.english': 'Angielski',
	'menu.language.polish': 'Polski',
	'menu.about': 'O nas',
	'menu.services': 'Usługi',
	'menu.services.aestheticDentistry': 'Stomatologia estetyczna',
	'menu.services.implantology': 'Implantologia',
	'menu.services.comprehensiveDentalCare': 'Kompleksowa opieka i radiologia stomatologiczna',
	'menu.services.aestheticMedicine': 'Medycyna estetyczna',
	'menu.team': 'Zespół',
	'menu.team.radoslaw': 'dr n. med. Radosław Łysek',
	'menu.team.karolina': 'Lek. dent. Karolina Krempa',
	'menu.team.paulina': 'Lek. dent. Paulina Gęsich',
	'menu.team.dorota': 'Dorota Maliszewska',
	'menu.team.monika': 'Monika Bochenek',
	'menu.team.elwira': 'Elwira Zawiślak',
	'menu.pricing': 'Cennik',
	'menu.blog': 'Blog',
	'menu.contact': 'Kontakt',

	'headerTop.aestheticDentistry': 'Stomatologia estetyczna',
	'headerTop.implantology': 'Implantologia',
	'headerTop.comprehensiveDentalCare': 'Kompleksowa opieka i radiologia stomatologiczna',
	'headerTop.aestheticMedicine': 'Medycyna estetyczna',

	'aboutUs.about': 'O nas',
	'aboutUs.about.test':
		'Dental <span2>Arts</span2> Studio powstało z myślą o wymagających pacjentach. Osobach, dla których liczy się to, jak świadczy się opiekę stomatologiczną. Bez pośpiechu. Z uwagą i starannością. Poznając zwyczaje i preferencje pacjenta. Dopasowując postępowanie terapeutyczne i zakres diagnostyki do zweryfikowanego zakresu potrzeb. Przekonasz się jak wiele zyskujesz, gdy decydujesz się na dedykowaną stomatologię. Już dziś umów się na konsultację. Z przyjemnością zapraszam na spotkanie.',
	'aboutUs.about.description':
		'<span>Dental Arts Studio</span> powstało z myślą o wymagających pacjentach. Osobach, dla których liczy się to, jak świadczy się opiekę stomatologiczną. Bez pośpiechu. Z uwagą i starannością. Poznając zwyczaje i preferencje pacjenta. Dopasowując postępowanie terapeutyczne i zakres diagnostyki do zweryfikowanego zakresu potrzeb. Przekonasz się jak wiele zyskujesz, gdy decydujesz się na dedykowaną stomatologię. Już dziś umów się na konsultację. Z przyjemnością zapraszam na spotkanie.',
	'aboutUs.dedicatedDentistry': 'Stomatologia dedykowana',
	'aboutUs.dedicatedDentistry.description':
		'<span>Sztuka stomatologii.</span> Dental Arts Studio wyznacza nową jakość. Wyobraź sobie płynną rejestrację, pyszną kawę na dzień dobry i personel, który zna Twoje potrzeby. Możesz liczyć na staranną obsługę w każdym zakresie opieki stomatologicznej. Otwieramy w Krakowie nowy gabinet. Styl powszechnie znany za granicą i określany mianem butikowej stomatologii, jest filozofią dedykowanych usług stomatologicznych.',
	'aboutUs.dedicatedDentistry.manifest': `<pbrown>Nasz manifest: stomatologia prestiżowa, dyskretna, unikalnie zorientowana na pacjenta. Ekskluzywna.</pbrown>`,
	'aboutUs.prestigeAsStandard': 'Prestiż w standardzie',
	'aboutUs.prestigeAsStandard.description1':
		'<span>Miejsce dla wymagających.</span> Stawiamy Twoje potrzeby ponad mnożenie zabiegów  i szybkie wizyty. Dbamy o efektywne wykorzystanie czasu. Nasza poczekalnia może być miejscem dla Ciebie, gdzie, mając do dyspozycji wiele ciekawych książek i magazynów, przy wybornej kawie, zrelaksujesz się, słuchając dobrej muzyki.',
	'aboutUs.prestigeAsStandard.description2':
		'<span>Więcej niż wizyta.</span> Uważnie analizujemy każdy przypadek wymagający zabiegu. Profilaktykę traktujemy kompleksowo. Stosujemy zaawansowaną stomatologię prewencyjną.',
	'aboutUs.prestigeAsStandard.description3':
		'<span>Stomatologia jakości.</span> Precyzja, staranność i wyjątkowa klasa – oto nasze priorytety. <span>Eliminujemy</span> niewygodę, przypadkowość i pospolitość. Zorientowani na Twoje potrzeby, skuteczni w działaniu,<span>niezwykle</span> dokładni…',
	'aboutUs.prestigeAsStandard.description4': 'Nasz Zespół w służbie Twojej satysfakcji.',
	'aboutUs.prestigeAsStandard.description5':
		'<span>Profesjonalizm podniesiony do potęgi komfortu.</span> Docenisz dyskrecję i kulturę, która towarzyszy każdemu spotkaniu. Chcemy, by poczucie wyjątkowości podniosło standard stomatologii.',

	'services.aestheticDentistry.naturalBeauty': 'naturalne piękno',
	'services.aestheticDentistry.naturalBeauty.description':
		'<span>Stomatologia estetyczna</span> jest projektowaniem pięknego uśmiechu. To zabiegi przywracania naturalnej atrakcyjności zębom, które wraz z upływem czasu poddały się szkodliwemu działaniu czynników zewnętrznych i nierzadko deficytów żywieniowych. Dopasujemy zęby o odpowiednim kształcie, odcieniu. Możliwość stosowania implantów, licówek czy koron pełnoceramicznych daje nieograniczone pole działania stomatologii estetycznej.',
	'services.aestheticDentistry.smileMetamorphosis': 'metamorfoza uśmiechu',
	'services.aestheticDentistry.smileMetamorphosis.description':
		'<span>Dental Arts Studio</span> Kraków posiada kompletne zaplecze umożliwiające kompleksowe zabiegi stomatologii estetycznej. Najnowocześniejsze i najbardziej zaawansowane technologicznie systemy wspierają doświadczenie i wiedzę naszego Personelu. Harmonia pięknych zębów to połączenie wiedzy, doświadczenia i materiałów stomatologicznych najwyższej jakości.',
	'services.aestheticDentistry.smileMetamorphosis.description.li4':
		'<span>wybielanie</span> – profesjonalne i bezpieczne metody przywracania prawidłowego odcienia zębów',
	'services.aestheticDentistry.smileMetamorphosis.description.li5':
		'<span>oczyszczanie i piaskowanie</span> – redukcja nalotów, osadów i przebarwień w bezpieczny sposób ',
	'services.aestheticDentistry.smileMetamorphosis.description.li1':
		'<span>implanty</span> – trwały i estetyczny zabieg, bezpieczeństwo dla szczęki, długa żywotność metody ',
	'services.aestheticDentistry.smileMetamorphosis.description.li2':
		'<span>licówki</span> – dla minimalnych i średnich korekt kształtu i koloru zębów ',
	'services.aestheticDentistry.smileMetamorphosis.description.li3':
		'<span>korony pełnoceramiczne</span> – możliwość naprawy bardzo zniszczonych zębów.',

	'services.implantology.safeCorrection': 'bezpieczna korekta',
	'services.implantology.safeCorrection.description':
		'Implantologia przychodzi z pomocą, zapewniając bezpieczną alternatywę dla protez i mostów. Technika wprowadzania implantów to profesjonalny i potwierdzony w swojej skuteczności zabieg. Gwarancja swobody jedzenia, mówienia z właściwą dykcją, brak ryzyka narażania sąsiednich zębów.',
	'services.implantology.benefitsForYou': 'korzyści dla Ciebie',
	'services.implantology.benefitsForYou.description':
		'Specjaliści Dental Arts Studio Kraków wprowadzają implanty z wykorzystaniem najnowszego sprzętu i materiałów stomatologicznych wyłącznie klasy premium. Piękny uśmiech – gwarantowany. Poczucie własnych, naturalnych zębów, bez dyskomfortu -zapewnione. Profilaktyka zaniku kości. Zabieg o stałym charakterze i długiej satysfakcjonującej eksploatacji.',
	'services.implantology.recommendations': 'zalecenia',
	'services.implantology.recommendations.head':
		'Dla powodzenia zabiegu implantacji/odbudowy kości koniecznie powinieneś:',
	'services.implantology.recommendations.td1': 'Usunąć z ust sterylny gazik dopiero 30 min po zabiegu',
	'services.implantology.recommendations.td2': 'Przyjmować leki zgodnie z zaleceniami',
	'services.implantology.recommendations.td3':
		'Dbać o higienę jamy ustnej używając bardzo miękkiej szczoteczki oraz płynu antyseptycznego',
	'services.implantology.recommendations.td4': 'Skontaktować się z nami gdy coś Cię niepokoi',
	'services.implantology.recommendations.td5': 'Stosować chłodne okłady na operowaną okolicę',
	'services.implantology.recommendations.td6': 'Pić tylko chłodne niegazowane napoje przez 48h po zabiegu',
	'services.implantology.recommendations.td7':
		'Jeść tylko chłodne, miękkie, półpłynne lekko doprawione potrawy przez 48 h po zabiegu',
	'services.implantology.recommendations.td8': 'Przyjść na wizytę kontrolną',
	'services.implantology.recommendations.head2':
		'Dla właściwego gojenia po zabiegu implantacji/odbudowy kości nie wolno:',
	'services.implantology.recommendations.td9': 'Pić gorących, gazowanych napojów przez 48 h',
	'services.implantology.recommendations.td10': 'Jeść przez 2 h po zabiegu',
	'services.implantology.recommendations.td11': 'Palić tytoniu, papierosów elektronicznych przez 48 h po zabiegu',
	'services.implantology.recommendations.td12':
		'Jeść gorących, mocno przyprawionych potraw oraz takich które wymagają mocnego gryzienia oraz przeżuwania przez 48 h po zabiegu',
	'services.implantology.recommendations.td13': 'Pić alkoholu przez 7 dni po zabiegu',
	'services.implantology.recommendations.td14': 'Ograniczyć wysiłek fizyczny do minimum przez 7 dni',
	'services.implantology.recommendations.td15': 'Nie korzystać z sauny, solarium przez 7 dni',
	'services.implantology.recommendations.td16':
		'Nie „sprawdzać” okolicy zabiegu językiem lub odchylając wargę, policzek',
	'services.implantology.recommendations.td17':
		'W przypadku zabiegu podniesienia dna zatoki szczękowej przez 7 dni unikać aktywności które wiążą się ze zmianą ciśnienia np. lotu samolotem, nurkowania, wycieczek górskich. Nie oczyszczać nosa poprzez wydmuchiwanie, zrezygnować z grania na instrumentach dętych np. flet, klarnet, trąbka, puzon.',

	'services.comprehensiveDentalCare.takingCareOfYourHealth': 'w trosce o zdrowie',
	'services.comprehensiveDentalCare.takingCareOfYourHealth.description':
		'Opieka premium. Zapewniamy najwyższy poziom opieki zapobiegawczo-leczniczej, staranną profilaktykę i bezbolesne leczenie. Regularne wizyty uchronią Cię przed koniecznością leczenia zębów, które odpowiednio wcześnie objęte ochroną stomatologiczną, dłużej utrzymają naturalną kondycję.',
	'services.comprehensiveDentalCare.treatmentAndPrevention': 'leczenie i profilaktyka',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description':
		'Lekarze i specjaliści Dental Arts Studio Kraków z pasją wypełniają zobowiązanie, którym jest troska o Twoje zdrowie. Dzięki profesjonalnej stomatologii zachowawczej, możemy zapobiegać próchnicowej erozji szkliwa. Leczymy ubytki bezboleśnie. Zapewniamy najwyższy komfort zabiegów. Endodoncja. Osiągamy najwyższy poziom precyzji w leczeniu chorób miazgi zęba oraz tkanek okołowierzchołkowych. Elektroniczne narzędzia wspierają nas w pomiarach i w wykonywaniu zabiegów. Najnowocześniejszy sprzęt pozwala na maksymalizację efektów leczenia.',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li1': 'konsultacje stomatologiczne',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li2': 'profesjonalna higienizacja',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li3': 'leczenie zachowawcze',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li4': 'endodoncja mikroskopowa',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li5': 'leczenie kanałowe',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li6': 'implantologia',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li7': 'rtg zębów',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li8': 'rtg punktowe',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li9': 'tomografia stomatologiczna',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li10': 'chirurgia',
	'services.comprehensiveDentalCare.treatmentAndPrevention.description.li11': 'pantomogram',

	'team.radoslaw.specialist': 'lekarz dentysta',
	'team.radoslaw.description':
		'Profesjonalista w dziedzinie stomatologii estetycznej oraz implanto-protetyki. Zajmuje się kompleksowym planowaniem leczenia z wykorzystaniem zawansowanych technologii oraz m.in. technik wizualnych. Koordynuje pracę specjalistów czuwając nad harmonogramem oraz kosztorysem. Twórca oraz właściciel Dental Arts Studio, które jest wyrazem wieloletnich przemyśleń na temat organizacji nowoczesnej opieki stomatologicznej. Ukończył wydział lekarski Collegium Medicum Uniwersytetu Jagiellońskiego gdzie obronił również pracę doktorską. Specjalista Zdrowia Publicznego. Uczestnik licznych kursów, których tematyka obejmuje minimalnie inwazyjne techniki pracy, odbudowy pełnoceramiczne, cyfrowe projektowanie uśmiechu, kompleksowe planowanie leczenia. Biegle włada językiem angielskim. Prywatnie miłośnik podróży oraz inwestycji giełdowych.',
	'team.karolina.specialist': 'lekarz dentysta',
	'team.karolina.description': `Absolwentka kierunku lekarsko- dentystycznego Collegium Medicum Uniwersytetu Jagiellońskiego w Krakowie. W codziennej pracy skupia się na stomatologii zachowawczej i protetyce.

	Praca stomatologa jest jej pasją.
	
	Aktywna uczestniczka licznych konferencji naukowych oraz kursów z zakresu nowoczesnej stomatologii. Jest osobą, która stale się rozwija i konsekwentnie poszerza swoją wiedzę i umiejętności praktyczne. W swojej pracy kieruje się dużą dokładnością i precyzją zabiegową.
	
	Do pacjentów podchodzi indywidualnie, starając się jak najlepiej przedstawić problemy i możliwości ich leczenia.
	
	Zwraca szczególną uwagę na komfort pacjentów oraz minimalizowanie stresu podczas wizyt. Prywatnie kolekcjonuje wspomnienia z podróży.`,
	'team.paulina.specialist': 'lekarz dentysta',
	'team.paulina.description':
		'Absolwentka kierunku lekarsko-dentystycznego Collegium Medicum Uniwersytetu Jagiellońskiego w Krakowie. Wykonuje zabiegi z zakresu leczenia kanałowego pierwotnego oraz powtórnego, usuwanie złamanych narzędzi i wkładów koronowo – korzeniowych, zamykania perforacji. Wszystkie zabiegi z zakresu endodoncji wykonuje w powiększeniu mikroskopu zabiegowego. Jest uczestnikiem i absolwentką licznych szkoleń, konferencji oraz zjazdów z zakresu endodoncji mikroskopowej, odbudowy estetycznej zębów, medycyny estetycznej i okluzji oraz leczenia starcia zębów. Jest absolwentką studiów podyplomowych Medycyny Estetycznej dla lekarzy Krakowskiej Wyższej Szkoły Promocji Zdrowia. Prywatnie pasjonuje się fotografią .Miłośniczka podróżowania i dobrej kuchni.',
	'team.dorota.specialist': 'Higienistka Stomatologiczna',
	'team.dorota.description':
		'Dorota Maliszewska Absolwentka Medycznego Studium Zawodowego. Związana ze stomatologia od 10 lat. Stale poszerza swoje zawodowe kompetencje uczestnicząc w szkoleniach oraz kursach z zakresu stomatologii i higienizacji. Motywacją do dalszego rozwoju zawodowego jest obserwowanie znakomitych efektów leczenia oraz zadowoleni pacjenci. Miła, energiczna i bardzo otwarta osoba.Lubi aktywnie spędzać czas wolny.',
	'team.monika.specialist': 'Higienistka Stomatologiczna',
	'team.monika.description':
		`Absolwentka Krakowskiej Medycznej Szkoły Policealnej, kierunek: Higienistka stomatologiczna. 
		Ze stomatologią związana od 2010 roku.
		Z natury optymistka, praca z ludźmi sprawia jej przyjemność. Dba o spokojną atmosferę podczas zabiegów.
		Zawsze miła i pomocna. Swoim uśmiechem przełamuje u pacjentów potencjalny stres, związany z wizytą u dentysty. Jej dobra energia udziela się Pacjentom. Nie ma dla niej spraw nie do rozwiązania.
		Profesjonalnie wykonuje zabiegi higienizacji oraz instruktaże higieny jamy ustnej.
		Specjalizuje się w metodzie Icon.
		Jej szczególnym umiłowaniem jest wybielanie zębów metodą natychmiastową oraz nakładkową.
		
		Bardzo sumienna i ambitna. Stale podnosi swoje kwalifikacje uczestnicząc od wielu lat w kursach z zakresu skalingu, polishingu , wybielania oraz metodzie Icon.
		 
		Prywatnie mam kilku letniego chłopca, w wolnym czasie stale szuka nowinek odnośnie higieny jamy ustnej, uwielbia piec oraz czytać , interesuje się również kinesiotapingiem.
		`,
	'team.elwira.specialist': 'Higienistka Stomatologiczna',
	'team.elwira.description':
		'Ukończyła Studium Madyczne w Krakowie, ze stomatologią związana od 8 lat. Absolwentka Uniwersytetu Rolniczego. Stale poszerzając swoją wiedzę i umiejętności. Obecnie w trakcie uzyskiwania uprawnień higienistki. W naszym gabinecie możecie ją poznać jako miłą, zawsze uśmiechnięta i chętną do pomocy osobę .Bardzo sumienna, oddana swojej pracy i pacjentom.',

	'pricing.stomatology': 'STOMATOLOGIA',
	'pricing.aestheticMedicine': 'MEDYCYNA ESTETYCZNA',
	'pricing.price': 'CENA*',
	'pricing.stomatology.td1': 'Przegląd',
	'pricing.stomatology.td2': 'Konsultacja',
	'pricing.stomatology.td3': 'Kosztorys leczenia',
	'pricing.stomatology.td4':
		'Pakiet diagnostyczny/plan leczenia – 2 wizyty (badanie, konsultacja, zdjęcie pantomograficzne, modele diagnostyczne, przygotowanie planu leczenia)',
	'pricing.stomatology.td5':
		'Projektowanie uśmiechu (badanie, konsultacja, wykonanie i analiza prototypu „nowego uśmiechu”)',

	'pricing.conservativeDentistry': 'STOMATOLOGIA ZACHOWAWCZA',
	'pricing.conservativeDentistry.td1': 'Wypełnienie kompozytowe - małe',
	'pricing.conservativeDentistry.td2': 'Odbudowa zęba pod pracę protetyczną',
	'pricing.conservativeDentistry.td3': 'Opatrunek tymczasowy',
	'pricing.conservativeDentistry.td4': 'Estetyczna korekta kształtu zęba materiałem kompozytowym',
	'pricing.conservativeDentistry.td5': 'Odbudowa zęba wkładem z włókna szklanego',
	'pricing.conservativeDentistry.td6': 'Odbudowa zęba wkładem z włókna szklanego – ząb jedno korzeniowy',
	'pricing.conservativeDentistry.td7': 'Odbudowa zęba wkładem z włókna szklanego – ząb wielokorzeniowy',

	'pricing.endodonticTreatment': 'LECZENIE ENDODONTYCZNE',
	'pricing.endodonticTreatment.td1': 'Interwencja endodontyczna (doraźna pomoc w razie bólu)',
	'pricing.endodonticTreatment.td2': 'Leczenie endodontyczne – siekacz, kieł',
	'pricing.endodonticTreatment.td3': 'Leczenie endodontyczne – przedtrzonowiec',
	'pricing.endodonticTreatment.td4': 'Leczenie endodontyczne – trzonowiec',
	'pricing.endodonticTreatment.td5': 'Leczenie endodontyczne powtórne – siekacz, kieł',
	'pricing.endodonticTreatment.td6': 'Leczenie endodontyczne powtórne – przedtrzonowiec',
	'pricing.endodonticTreatment.td7': 'Leczenie endodontyczne powtórne – trzonowiec',
	'pricing.endodonticTreatment.td8': 'Odbudowa zniszczonego zęba do leczenia endodntycznego',
	'pricing.endodonticTreatment.td9': 'Usunięcie starego wkładu koronowo-korzeniowego',

	'pricing.aesthetics': 'ESTETYKA/PROTETYKA',
	'pricing.aesthetics.td1': 'Licówka kompozytowa',
	'pricing.aesthetics.td2': 'Licówka porcelanowa',
	'pricing.aesthetics.td3': 'Korona kompozytowa',
	'pricing.aesthetics.td4': 'Korona porcelanowa na metalu',
	'pricing.aesthetics.td5': 'Korona pełnoceramiczna',
	'pricing.aesthetics.td6': 'Inlay / Onlay / Overlay porcelanowy',
	'pricing.aesthetics.td7': 'Inlay / Onlay / Overlay kompozytowy',
	'pricing.aesthetics.td8': 'Tymczasowa korona / licówka',
	'pricing.aesthetics.td9': 'Usunięcie starej korony',
	'pricing.aesthetics.td10': 'Proteza akrylowa częściowa (do 7 zębów)',
	'pricing.aesthetics.td11': 'Proteza akrylowa częściowa (do 8 zębów)',
	'pricing.aesthetics.td12': 'Proteza szkieletowa',
	'pricing.aesthetics.td13': 'Prace kombinowane',
	'pricing.aesthetics.td13.description': 'wycena indywidualna na podstawie planu leczenia',

	'pricing.implantology': 'IMPLANTOLOGIA / CHIRURGIA',
	'pricing.implantology.td1': 'Implant',
	'pricing.implantology.td2': 'Podniesienie dna zatoki szczękowej',
	'pricing.implantology.td3': 'Augumentacja',
	'pricing.implantology.td4': 'Proste usunięcie zęba',
	'pricing.implantology.td5': 'Chirurgiczne usunięcie zęba',
	'pricing.implantology.td6': 'Korona na implancie – ceramiczna na łączniku indywidualnym',
	'pricing.implantology.td7': 'Korona na implancie – stalowa na łaczniku standardowym',
	
	'pricing.orthodonticTreatment': 'LECZENIE ORTODONTYCZNE',
	'pricing.orthodonticTreatment.td1': 'Konsultacja ortodontyczna',
	'pricing.orthodonticTreatment.td2': 'Aparat stały metalowy',
	'pricing.orthodonticTreatment.td3': 'Aparata stały estetyczny',
	'pricing.orthodonticTreatment.td4': 'Aparat stały mieszany',
	'pricing.orthodonticTreatment.td5': 'Aparat wyjmowany czynnościowy',

	'pricing.bleaching': 'WYBIELANIE / HIGIENIZACJA / PROFILAKTYKA',
	'pricing.bleaching.td1': 'Wybielanie domowe – nakładkowe',
	'pricing.bleaching.td2': 'Wybielanie w gabinecie – systemem Prevdent',
	'pricing.bleaching.td3': 'Wybielanie przebarwionego zęba po leczeniu kanałowym – pierwsza wizyta',
	'pricing.bleaching.td4': 'Wybielanie przebarwionego zęba po leczeniu kanałowym – kolejna wizyta',
	'pricing.bleaching.td5': 'Fluoryzacja kontaktowa',
	'pricing.bleaching.td6': 'Higienizacja + profesjonalne Wybielanie - systemem BlancOne',
	'pricing.bleaching.td7': 'Higienizacja jamy ustnej (usunięcie kamienia, piaskowanie, fluoryzacja)',

	'pricing.xRay': 'RTG',
	'pricing.xRay.td1': 'rtg punktowe',
	'pricing.xRay.td2': 'rtg pantomograficzne',
	'pricing.xRay.td3': 'rtg cefalometryczne',
	'pricing.xRay.td4': 'cbct - endo ct',

	'pricing.record': 'UDOSTĘPNIENIE DOKUMENTACJI MEDYCZNEJ',
	'pricing.record.td1': 'Odpis za jedną stronę',
	'pricing.record.td2': 'Kopia/Wydruk za jedną stronę',
	'pricing.record.td3': 'Dokumentacja na informatycznym nośniku danych(całość dokumentacji)',

	'pricing.azzalure': 'Cennik zabiegów preparatem <img/>',
	'pricing.azzalure.area': 'Okolica podania',
	'pricing.azzalure.td1': 'lwia zmarszczka (zmarszczka podłużna czoła)',
	'pricing.azzalure.td2': 'zmarszczki poziome czoła',
	'pricing.azzalure.td3': 'zmarszczki poziome + lwia zmarszczka',
	'pricing.azzalure.td4': 'kurze łapki',
	'pricing.azzalure.td5': 'kurze łapki + lwia zmarszczka',
	'pricing.azzalure.td6': 'kurze łapki + zmarszczki poziome + lwia',
	'pricing.azzalure.td7': 'zmarszczka',
	'pricing.azzalure.td8': 'zmarszczki nosa (zmarszczki królicze)',
	'pricing.azzalure.td9': 'uśmiech dziąsłowy',
	'pricing.azzalure.td10': 'broda',
	'pricing.azzalure.td11': 'leczenie bruksizmu',

	'pricing.hyperhidrosis': 'Nadpotliwość pierwotna',
	'pricing.hyperhidrosis.td1': 'pachy/stopy/dłonie',

	'pricing.mesotherapy': 'Mezoterapia igłowa',
	'pricing.mesotherapy.th1': 'ilość zabiegów',
	'pricing.mesotherapy.th2': '1 zabieg',
	'pricing.mesotherapy.th3': '4 zabiegi',
	'pricing.mesotherapy.th4': 'Twarz lub dekolt',
	'pricing.mesotherapy.th5': 'Skóra owłosiona głowy',
	'pricing.mesotherapy.td1': 'Neauvia Hydro Deluxe',
	'pricing.mesotherapy.td2': 'Filorga NCTF 135HA',
	'pricing.mesotherapy.td3': 'Linerase (twarz, szyja, dekolt i dłonie jednocześnie)',
	'pricing.mesotherapy.td4': 'terapia wzmacniająca dr. Cyj',
	'pricing.mesotherapy.td5': 'Dermaheal HL',

	'pricing.injection': 'Lipoliza iniekcyjna',
	'pricing.injection.ampule': '1 ampułka',
	'pricing.injection.td1': 'Aqualyx',
	'pricing.injection.td1.description': 'Przy 3 lub więcej ampułkach – 250 /amp',
	'pricing.injection.td2': 'Belkyra - zabieg na podwójny podbródek',
	'pricing.injection.td3': 'leczenie cellulitu',
	'pricing.injection.td3.description1': '7 zabiegów',
	'pricing.injection.td3.description2': '12 zabiegów',

	'pricing.neauvia': 'Wypełniacze z kwasem hialuronowym NEAUVIA®',
	'pricing.neauvia.area': 'Miejsce podania',
	'pricing.neauvia.td1': 'Wypełnianie zmarszczek i bruzd',
	'pricing.neauvia.td2': 'Wolumetria twarzy (modelowanie owalu)',
	'pricing.neauvia.td3': 'Modelowanie nosa lub brody',
	'pricing.neauvia.td4': 'Wypełnianie doliny łez (Redensity II)',
	'pricing.neauvia.td5': 'Modelowanie i wypełnianie ust',

	'pricing.emerge': 'Laser frakcyjny EMERGE',
	'pricing.emerge.th1': 'Okolica podania',
	'pricing.emerge.th2': '1 zabieg pakiet',
	'pricing.emerge.th3': '4 zabiegów + 1 gratis',
	'pricing.emerge.td1': 'Twarz',
	'pricing.emerge.td2': 'Szyja lub dekolt',
	'pricing.emerge.td3': 'Twarz + szyja',
	'pricing.emerge.td4': 'Twarz + szyja + dekolt',
	'pricing.emerge.td5': 'Okolica oczu (kurze łapki)',
	'pricing.emerge.td6': 'Okolica ust (zmarszczki palacza)',
	'pricing.emerge.td7': 'Czoło',
	'pricing.emerge.td8': 'Policzki',
	'pricing.emerge.td9': 'Dłonie',

	'pricing.scars': 'Leczenie blizn lub rozstępów',
	'pricing.scars.th1': 'Powierzchnia obszaru poddawanego zabiegowi',
	'pricing.scars.th2': '1 zabieg Pakiet',
	'pricing.scars.th3': '4 zabiegów + 1 gratis* Pakiet',
	'pricing.scars.th4': '8 zabiegów + 2 gratis**',
	'pricing.scars.td1': 'do 5 cm2',
	'pricing.scars.td2': 'od 5 do 10 cm2',
	'pricing.scars.td3': 'od 10 do 15 cm2',
	'pricing.scars.td4': 'powyżej 15 cm2',
	'pricing.scars.td4.description': 'cena ustalana indywidualnie',

	'pricing.zl': '* ceny podane w złotych polskich, PLN',

	'contact.address': 'Adres:',
	'contact.open': 'Godziny otwarcia',
	'contact.monday': 'Poniedziałek',
	'contact.tuesday': 'Wtorek',
	'contact.wednesday': 'Środa',
	'contact.thursday': 'Czwartek',
	'contact.friday': 'Piątek',
	'contact.saturday': 'Sobota',
	'contact.sunday': 'Niedziela',
	'contact.weekHour': '8:00 - 21:00',
	'contact.close': 'Zamknięte',
	'contact.onDuty': 'Dyżurna',

	'contact.goTo': 'Prowadź do',
	'contact.openMaps': 'Otwórz Google Maps'
};