module.exports = {
	'menu.about': 'Om oss',
	'menu.services': 'Usługi',
	'menu.services.aestheticDentistry': 'Estetisk tandvård',
	'menu.services.implantology': 'Tand implantat',
	'menu.services.comprehensiveDentalCare': 'Kompleksowa opieka i radiologia stomatologincza',
	'menu.services.aestheticMedicine': 'Estetisk medicin',
	'menu.team': 'Zespół',
	'menu.team.radoslaw': 'dr n. med. Radosław Łysek',
	'menu.team.paulina': 'Lek. dent. Paulina Gęsich',
	'menu.team.dorota': 'Dorota Maliszewska',
	'menu.team.elwira': 'Elwira Zawiślak',
	'menu.pricing': 'Cennik',
	'menu.blog': 'Blog',
	'menu.contact': 'Kontakt',

	'headerTop.aestheticDentistry': 'Estetisk tandvård',
	'headerTop.implantology': 'Tand implantat',
	'headerTop.comprehensiveDentalCare': 'Kompleksowa opieka i radiologia stomatologincza',
	'headerTop.aestheticMedicine': 'Estetisk medicin',

    'aboutUs.about': 'Om oss',
	'aboutUs.about.test':`
        är designad för krävande patienter. Människor för vilka det räknas är hur tandvård tillhandahålls. Utan brådska. Med uppmärksamhet och flit. Att känna till patientens vanor och preferenser. Genom att anpassa det terapeutiska förfarandet och omfattningen av diagnosen till de kontrollerade behoven. Du får reda på hur mycket du får när du bestämmer dig för en dedikerad tandläkare. Gör en konsultation idag. Vi är glada att bjuda in dig att träffas.
    `,
	'aboutUs.about.description':`
        är designad för krävande patienter. Människor för vilka det räknas är hur tandvård tillhandahålls. Utan brådska. Med uppmärksamhet och flit. Att känna till patientens vanor och preferenser. Genom att anpassa det terapeutiska förfarandet och omfattningen av diagnosen till de kontrollerade behoven. Du får reda på hur mycket du får när du bestämmer dig för en dedikerad tandläkare. Gör en konsultation idag. Vi är glada att bjuda in dig att träffas.
    `,
    'aboutUs.dedicatedDentistry': 'Dedikerad tandvård',
	'aboutUs.dedicatedDentistry.description': `
        Dental Arts Studio sätter en ny kvalitet. Föreställ dig en smidig registrering, ett utsökt kaffe för en bra dag och en personal som vet dina behov. Du kan räkna med omsorgsfull service i alla typer av tandvård.
    `,

	'aboutUs.about.hightLight.dentalArtStudio': 'Dental Arts Studio',
	'aboutUs.about.hightLight.theArtOfDentistry': 'Konsten att tandvård.',
};
