import { Link } from 'gatsby';
// import PropTypes from "prop-types"
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../styles/nav.scss';
import classNames from 'classnames';
import plFlag from '../images/icon/pl.svg';
import enFlag from '../images/icon/en.svg';
// import Image from "../components/image"
import Logo from '../images/logo.svg';
import newPhone from '../images/icon/new-phone.png';
import { FormattedMessage } from 'react-intl';

const onMouseLeave = (event) => {
	// console.log(event);
	event.preventDefault();
	event.currentTarget.querySelector('.drop-down').style.display = 'none';
};
const onMouseEnter = (event) => {
	// console.log(event);
	event.preventDefault();
	event.currentTarget.querySelector('.drop-down').style.display = 'block';
	//   this.setState({ show: "test" })
};

const handleClick = () => {
	// console.log('click');
	window.medicall_modal('f78b07a362fecf4542fa', 1);

	return false;
};

const Nav = ({ location, lang }) => {
	// console.log('nav', location, lang);
	// console.log('nav', location.pathname, lang.langKey + 'Flag');
	const [show, setShow] = useState(false);

	const showMenu = (event) => {
		// 	// console.log('click');
		setShow(!show);
	};
	const classMenu = classNames({
		show: show,
		none: !show,
		nav: true,
		'main-ul': true
	});
	const onlyPL = lang.langKey === 'pl';
	const currentLang = lang.langKey === 'pl' ? '' : `/${lang.langKey}`;
	const flagIcon = () => {
		switch (lang.langKey) {
			case 'pl':
				return plFlag;
			case 'en':
				return enFlag;
			default:
				return plFlag
		}
	};
	return (
		<div>
			<div className="menu-mobile only-mobile">
				<FontAwesomeIcon
					className="only-mobile"
					// for="menu"
					onClick={showMenu}
					icon="bars"
					size="2x"
				/>
				<img className="" src={Logo} alt="logo" />
			</div>
			<nav className={classMenu}
				onClick={showMenu}
			>
				<Link to="/">
					<img className="logo-menu" src={Logo} alt="logo" />
				</Link>
				<input type="checkbox" className="input-show-menu" name="menu" />
				<div className="nav-tab">
					<ul className="main-ul top-nav">
						<li className="languages" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
							<FormattedMessage id="menu.language">{(txt) => <p>{txt}</p>}</FormattedMessage>
							<img className="flag" src={flagIcon()} alt="Wybrany język" />
							<ul className="drop-down">
								<li>
									<Link to="/">
										<FormattedMessage id="menu.language.polish">
											{(txt) => <p>{txt}</p>}
										</FormattedMessage>

										<img className="flag" src={plFlag} alt="Język polski" />
									</Link>
								</li>
								<li>
									<Link to="/en">
										<FormattedMessage id="menu.language.english">
											{(txt) => <p>{txt}</p>}
										</FormattedMessage>

										<img className="flag" src={enFlag} alt="Język angielski" />
									</Link>
								</li>
							</ul>
						</li>
						<li className="register-top nowrap" onClick={handleClick}>
							<FormattedMessage id="menu.register" />
						</li>
						<a href="tel:50850802">
							<li className="phone-top">
								<img className="icon shake" src={newPhone} style={{ marginRight: '10px' }} alt="numer telefonu" />508 502 802
							</li>
						</a>
					</ul>
					<ul className="main-ul">
						<li>
							<Link to={lang.langKey === 'pl' ? '/' : `${currentLang}/`} activeClassName="selected">
								<FormattedMessage id="menu.about" />
							</Link>
						</li>
						<li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
							<Link
								to={`${currentLang}/uslugi`}
								activeClassName="selected"
								partiallyActive={true}
								className="trigger-drop-down"
							>
								<FormattedMessage id="menu.services" />
							</Link>
							<ul className="drop-down">
								<li>
									<Link to={`${currentLang}/uslugi/stomatologia-estetyczna`}>
										<FormattedMessage id="menu.services.aestheticDentistry" />
									</Link>
								</li>
								<li>
									<Link to={`${currentLang}/uslugi/implantologia`}>
										<FormattedMessage id="menu.services.implantology" />
									</Link>
								</li>
								<li>
									<Link to={`${currentLang}/uslugi/kompleksowa-opieka`}>
										<FormattedMessage id="menu.services.comprehensiveDentalCare">
											{(txt) => {
												return currentLang === '' ? (
													<div>
														<p>Kompleksowa opieka </p>
														<p>i radiologia stomatologiczna</p>
													</div>
												) : (
														txt
													);
											}}
										</FormattedMessage>
									</Link>
								</li>
								<li>
									<Link to={`${currentLang}/uslugi/medycyna-estetyczna`}>
										<FormattedMessage id="menu.services.aestheticMedicine" />
									</Link>
								</li>
							</ul>
						</li>
						<li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
							<Link
								to={`${currentLang}/zespol`}
								activeClassName="selected"
								partiallyActive={true}
								className="trigger-drop-down"
							>
								<FormattedMessage id="menu.team" />
							</Link>

							<ul className="drop-down">
								<li>
									<Link to={`${currentLang}/zespol/radoslaw-lysek`}>dr n. med. Radosław Łysek</Link>
								</li>
								<li>
									<Link to={`${currentLang}/zespol/paulina-gesich`}>lek. dent. Paulina Gęsich</Link>
								</li>
								<li>
									<Link to={`${currentLang}/zespol/karolina-krempa`}>lek. dent. Karolina Krempa</Link>
								</li>
								<li>
									<Link to={`${currentLang}/zespol/dorota-maliszewska`}>Dorota Maliszewska</Link>
								</li>
								<li>
									<Link to={`${currentLang}/zespol/monika-bochenek`}>Monika Bochenek</Link>
								</li>
							</ul>
						</li>
						<li>
							<Link to={`${currentLang}/cennik`} activeClassName="selected">
								<FormattedMessage id="menu.pricing" />
							</Link>
						</li>
						{onlyPL && (
							<li>
								<Link to={`${currentLang}/blog`} activeClassName="selected">
									<FormattedMessage id="menu.blog" />
								</Link>
							</li>
						)}
						<li>
							<Link to={`${location.pathname}#contact`}>
								<FormattedMessage id="menu.contact" />
							</Link>
						</li>
					</ul>
				</div>
			</nav>
		</div>
	);
}

export default Nav;
