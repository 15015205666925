import React, { Component } from 'react';

class Phone extends Component {
    state = {
        width: 0
    }
	componentDidMount() {
        // console.log(this.state);
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions = () => {
        // console.log("fff");
        // console.log(this.state);


		this.setState({ width: window.innerWidth});
	}

	render() {
		return (
			<div>
				{this.state.width < 800 && (
					<a href="tel:508 502 802">
						<div className="phone">
							<div className="phone-icon" />
						</div>
						<div className="shadow-phone" />
						<div className="border-phone" />
					</a>
				)}
			</div>
		);
	}
}

export default Phone;
